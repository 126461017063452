import React from 'react'
import WorkDescription from "../../view/WorkDescription/index";
const index = () => {
  return (
    <>
    <WorkDescription/>
    </>
  )
}

export default index