import React from 'react'
import UploadSignature from '../../view/UploadSignature'

const index = () => {
  return (
    <>
    <UploadSignature/>
    </>
  )
}

export default index